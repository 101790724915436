<template>
	<transition name="toast">
		<Toast v-if="showToast" :success="isSuccess" :message="toastMessage" />
	</transition>
	<div class="container">
		<div id="add-card" class="card text-center">
			<h4>Új ügyfél felvétele</h4>
			<form @submit.prevent="handleSave" class="group">
				<input
					class="input"
					type="text"
					v-model="name"
					@input="nameValidation"
					maxlength="30"
					required
					placeholder="Név"
					:class="{ invalid: isNameValid === false }"
				/>
				<input
					class="input"
					type="text"
					v-model="birth"
					@input="birthValidation"
					maxlength="11"
					required
					placeholder="Születési idő"
					:class="{ invalid: isBirthValid === false }"
				/>
				<div class="radio-group">
					<input type="radio" id="id-card" value="id-card" v-model="docSelect" checked />
					<label for="id-card">Személyi</label>
					<input
						type="radio"
						id="drivers-license"
						value="drivers-license"
						v-model="docSelect"
					/>
					<label for="drivers-license">Jogosítvány</label>
				</div>
				<input
					v-if="docSelect === 'id-card'"
					class="input"
					type="text"
					v-model="document"
					@input="documentValidation"
					maxlength="30"
					required
					placeholder="Személyi"
					:class="{ invalid: isDocumentValid === false }"
				/>
				<input
					v-if="docSelect === 'drivers-license'"
					class="input"
					type="text"
					v-model="document"
					@input="documentValidation"
					maxlength="30"
					required
					placeholder="Jogosítvány"
					:class="{ invalid: isDocumentValid === false }"
				/>
				<input
					class="input"
					type="text"
					v-model="address"
					@input="addressValidation"
					maxlength="100"
					required
					placeholder="Lakcím"
					:class="{ invalid: isAddressValid === false }"
				/>
				<input
					class="input"
					type="text"
					v-model="phone"
					@input="phoneValidation"
					maxlength="17"
					required
					placeholder="Telefonszám"
					:class="{ invalid: isPhoneValid === false }"
				/>
				<input
					class="input"
					type="text"
					v-model="email"
					@input="emailValidation"
					placeholder="E-mail cím (opcionális)"
					:class="{ invalid: isEmailValid === false }"
				/>
				<button :class="{ disabled: !isFormValid }">Új ügyfél felvétele</button>
			</form>
			<p>{{ clientID }}</p>
		</div>
	</div>
</template>

<script>
// firebase imports
import { db } from '../firebase/config';
import { collection, addDoc } from 'firebase/firestore';

// libraries
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import slugify from 'slugify';

// composables
import useToaster from '../composables/useToaster';

// components
import Toast from '../components/Toast.vue';

export default {
	name: 'NewClientForm',
	components: { Toast },
	setup() {
		const router = useRouter();

		const { showToast, isSuccess, toastMessage, triggerToast } = useToaster();

		const handleSave = () => {
			// collection reference
			const colRef = collection(db, 'clients');

			addDoc(colRef, {
				cid: clientID.value,
				name: name.value,
				birth: birth.value,
				doctype: docSelect.value,
				document: document.value,
				address: address.value,
				phone: phone.value,
				email: email.value,
			})
				.then(() => triggerToast(true, 'Sikeres mentés'))
				.then(() => {
					setTimeout(() => {
						router.push({ name: 'Clients', query: { user: clientID.value } });
					}, 3500);
				});
		};

		const regex = {
			name: /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{3,30})$/,
			birth: /^([0-9]{4}.[0-9]{2}.[0-9]{2}.)$/,
			document: /^([0-9a-zA-Z ,.'-/]{3,30})$/,
			address:
				/^([0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-/]{4,100})$/,
			phone: /^(\+)?([ 0-9]){10,17}$/,
			email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
		};

		const name = ref('');
		const isNameValid = ref(null);
		const birth = ref('');
		const isBirthValid = ref(null);
		const docSelect = ref('id-card');
		const document = ref('');
		const isDocumentValid = ref(null);
		const address = ref('');
		const isAddressValid = ref(null);
		const phone = ref('');
		const isPhoneValid = ref(null);
		const email = ref('');
		const isEmailValid = ref(null);

		const isFormValid = ref(false);

		const nameValidation = () => {
			// console.log('typing');
			if (name.value && regex.name.test(name.value)) {
				// name valid
				isNameValid.value = true;
			} else {
				// name invalid
				isNameValid.value = false;
			}
			validate();
		};

		const birthFormat = (e) => {
			// console.log(e.inputType);
			if (birth.value.length === 4 || birth.value.length === 7 || birth.value.length === 10) {
				birth.value += '.';
			}

			if (birth.value.length === 5 || birth.value.length === 8 || birth.value.length === 11) {
				if (e.inputType === 'deleteContentBackward') {
					birth.value = birth.value.slice(0, -2);
				}
			}
		};

		const birthValidation = (e) => {
			// console.log('typing');
			birthFormat(e);
			if (birth.value && regex.birth.test(birth.value)) {
				// birth valid
				isBirthValid.value = true;
			} else {
				// birth invalid
				isBirthValid.value = false;
			}
			validate();
		};

		const documentValidation = () => {
			// console.log('typing');
			if (document.value && regex.document.test(document.value)) {
				// document valid
				isDocumentValid.value = true;
			} else {
				// document invalid
				isDocumentValid.value = false;
			}
			validate();
		};

		const addressValidation = () => {
			// console.log('typing');
			if (address.value && regex.address.test(address.value)) {
				// address valid
				isAddressValid.value = true;
			} else {
				// address invalid
				isAddressValid.value = false;
			}
			validate();
		};

		const phoneValidation = () => {
			// console.log('typing');
			if (phone.value && regex.phone.test(phone.value)) {
				// phone valid
				isPhoneValid.value = true;
			} else {
				// phone invalid
				isPhoneValid.value = false;
			}
			validate();
		};

		const emailValidation = () => {
			// console.log('typing');
			if (!email.value) {
				// email null
				isEmailValid.value = null;
			} else if (email.value && regex.email.test(email.value)) {
				// email valid
				isEmailValid.value = true;
			} else {
				// email invalid
				isEmailValid.value = false;
			}
			validate();
		};

		const clientID = ref('');

		const slugName = ref('');
		const slugBirth = ref('');
		const slugDocument = ref('');

		const generateClientID = () => {
			slugName.value = slugify(name.value, {
				replacement: '',
				lower: true,
			});

			slugBirth.value = birth.value.split('.').join('');

			slugDocument.value = slugify(document.value, {
				replacement: '',
				lower: true,
			});

			clientID.value = slugName.value + slugBirth.value + '-' + slugDocument.value;
		};

		const validate = () => {
			generateClientID();
			// console.log('validate');
			if (
				isNameValid.value &&
				isBirthValid.value &&
				isDocumentValid.value &&
				isAddressValid.value &&
				isPhoneValid.value
			) {
				isFormValid.value = true;
			} else {
				isFormValid.value = false;
			}
		};

		return {
			showToast,
			isSuccess,
			toastMessage,
			triggerToast,
			handleSave,
			name,
			isNameValid,
			birth,
			isBirthValid,
			docSelect,
			document,
			isDocumentValid,
			address,
			isAddressValid,
			phone,
			isPhoneValid,
			email,
			isEmailValid,
			nameValidation,
			birthValidation,
			documentValidation,
			addressValidation,
			phoneValidation,
			emailValidation,
			isFormValid,
			clientID,
		};
	},
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	width: 80%;
	max-width: 800px;
	align-items: center;
	justify-content: center;
	filter: drop-shadow(var(--module-shadow));
}
</style>
