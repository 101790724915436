<template>
	<div id="admin">
		<Navbar />
		<div id="dashboard">
			<SearchClients />
		</div>
	</div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import SearchClients from '../components/SearchClients.vue';

export default {
	name: 'Clients',
	components: { Navbar, SearchClients },
};
</script>

<style></style>
