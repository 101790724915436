import { createRouter, createWebHistory } from 'vue-router';

// view components
import Home from '../views/Home.vue';
import Clients from '../views/Clients.vue';
import NewClient from '../views/NewClient.vue';
import Worksheets from '../views/Worksheets.vue';
import Login from '../views/Login.vue';

// firebase imports
import { auth } from '../firebase/config';

const requireAuth = (to, from, next) => {
	let user = auth.currentUser;
	if (!user) {
		next({ name: 'Login' });
	} else {
		next();
	}
};

const requireNoAuth = (to, from, next) => {
	let user = auth.currentUser;
	if (user) {
		next({ name: 'Home' });
	} else {
		next();
	}
};

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
		beforeEnter: requireNoAuth,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		beforeEnter: requireAuth,
	},
	{
		path: '/clients',
		name: 'Clients',
		component: Clients,
		beforeEnter: requireAuth,
	},
	{
		path: '/new-client',
		name: 'NewClient',
		component: NewClient,
		beforeEnter: requireAuth,
	},
	{
		path: '/worksheets',
		name: 'Worksheets',
		component: Worksheets,
		beforeEnter: requireAuth,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
