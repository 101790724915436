<template>
	<div id="admin">
		<Navbar />
		<div id="dashboard">
			<Dashboard />
		</div>
	</div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Dashboard from '../components/Dashboard.vue';

export default {
	name: 'Worksheets',
	components: { Navbar, Dashboard },
};
</script>

<style></style>
