import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDUDyft0pvY2_G4d7FkI_9Fj0rYrNdlvpI',
	authDomain: 'southern-guitar-service.firebaseapp.com',
	projectId: 'southern-guitar-service',
	storageBucket: 'southern-guitar-service.appspot.com',
	messagingSenderId: '262129394969',
	appId: '1:262129394969:web:e68635ec490448fd519188',
	measurementId: 'G-6TSDLX9EF0',
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();

export { db, auth };
