<template>
	<nav>
		<div id="nav-top">
			<img class="logo" src="../assets/logo.svg" alt="Southern Guitar Service" />
			<div class="user">Bejelentkezve: {{ loggedInUser }}</div>
			<button @click="handleLogout">Kijelentkezés</button>
		</div>

		<div id="nav-bottom">
			<router-link :to="{ name: 'Home' }" class="nav-item">
				<span class="material-symbols-rounded">home</span>
				Kezdőlap
			</router-link>
			<router-link :to="{ name: 'Clients' }" class="nav-item">
				<span class="material-symbols-rounded">group</span>
				Meglévő ügyfelek
			</router-link>
			<router-link :to="{ name: 'NewClient' }" class="nav-item">
				<span class="material-symbols-rounded">person_add</span>
				Új ügyfél felvétele
			</router-link>
			<!-- <router-link :to="{ name: 'Worksheets' }" class="nav-item">
				<span class="material-symbols-rounded">description</span>
				Munkalapok
			</router-link> -->
		</div>
	</nav>
</template>

<script>
// firebase imports
import { auth } from '../firebase/config';
import { signOut } from 'firebase/auth';

// libraries
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

// composables
import getUser from '../composables/getUser';

export default {
	name: 'Navbar',
	setup() {
		const { user } = getUser();
		const router = useRouter();

		const loggedInUser = ref(null);

		if (user.value.email === 'galyaszrobert@gmail.com') {
			loggedInUser.value = 'Admin';
		} else if (user.value.email === 'southernguitarservice@gmail.com') {
			loggedInUser.value = 'Nagy Gábor';
		}

		const handleLogout = () => {
			signOut(auth);
		};

		watchEffect(() => {
			if (!user.value) {
				router.push({ name: 'Login' });
			}
		});

		return { handleLogout, loggedInUser };
	},
};
</script>

<style lang="scss">
@import '../assets/mixins';

nav {
	#nav-top {
		width: 100%;
		padding-bottom: 1.25rem;
		text-align: center;
		border-bottom: 1px solid var(--main-background);

		.logo {
			display: inline-block;
			width: 80%;
			margin-bottom: 1rem;
			@include noselect;
		}

		.user {
			font-size: 0.75rem;
			font-weight: bold;
			margin-bottom: 1.5rem;
			@include noselect;
		}

		button {
			font-size: 0.9rem;
		}
	}

	#nav-bottom {
		width: 100%;
		padding: 0.5rem 0;

		.nav-item {
			display: inline-flex;
			width: 100%;
			padding: 0.5rem 0;
			color: var(--nav-item-color);
			align-items: center;
			@include noselect;

			.material-symbols-rounded {
				margin-right: 0.666rem;
			}

			&.router-link-exact-active {
				color: var(--nav-item-active-color);
			}
		}
	}
}
</style>
