<template>
	<div class="toast" :class="{ success: success, error: !success }">{{ message }}</div>
</template>

<script>
export default {
	props: ['success', 'message'],
	/* setup(props) {
		console.log(props);
	}, */
};
</script>

<style lang="scss">
.toast {
	position: fixed;
	top: 2rem;
	padding: 1.25rem;
	color: var(--light);
	background: var(--dark);
	border-radius: var(--elem-border-radius);
	box-shadow: var(--toast-shadow);
	z-index: 9999;

	&.success {
		color: var(--light);
		background: var(--success);
	}

	&.error {
		color: var(--light);
		background: var(--error);
	}
}
</style>
